import { OperationType } from '@firebase/auth';
import { createSlice } from '@reduxjs/toolkit'
export const bookingActivitySlice = createSlice({
    name: 'bookingActivity',
    initialState: {
        isLoading: false,
        operationType: "",
        activityList: [],
        activityChan: undefined,
        activityObj: undefined,
        facilities: [],
        activityLoading: false,
        activityNames: []
    },
    reducers: {
        createBookingActivity: (state, action) => {
            state.isLoading = true;
        },
        createBookingActivitySuccess: (state, action) => {
            state.isLoading = false;
            state.operationType = "CREATE_BOOKING_ACTIVITY_SUCCESS"
        },
        requestFailed: (state, action) => {
            state.isLoading = false;
            state.operationType = ""
            state.activityLoading = false
        },
        fetchActivity: (state, action) => {
            state.isLoading = true;
        },
        requestSuccess: (state, action) => {
            if (action.payload) {
                Object.assign(state, action.payload);
            }
        },
        resetOperations: (state, action) => {
            state.operationType = "";
        },
        getActivityById: (state, action) => {
            state.isLoading = true;
            state.activityLoading = true
        },
        deleteActivity: (state, action) => {
            state.isLoading = true;
        },
        resetValues: (state, action) => {
            state.activityObj = undefined;
        },
        fetchFacilities: (state, action) => {
            state.isLoading = true;
        },
        resetStateValues: (state, action) => {
            state[action.payload] = undefined;
        },
        getAllActivityNames: (state, action) => {
            state.isLoading = true;
        },
        duplicateActivity: (state, action) => {
            state.isLoading = true;
        }
    }
})

export const { createBookingActivity,
    createBookingActivitySuccess,
    requestFailed,
    fetchActivity,
    requestSuccess,
    resetOperations,
    getActivityById,
    deleteActivity,
    resetValues,
    fetchFacilities,
    resetStateValues,
    getAllActivityNames,
    duplicateActivity
} = bookingActivitySlice.actions

export default bookingActivitySlice.reducer